export default [{
  value: 'male',
  name: 'Male'
}, {
  value: 'female',
  name: 'Female'
}, {
  value: 'trans-masculine',
  name: 'Trans Masculine'
}, {
  value: 'trans-feminine',
  name: 'Trans Feminine'
}, {
  value: 'non-binary',
  name: 'Non-Binary'
}, {
  value: 'agender',
  name: 'Agender'
}, {
  value: 'genderqueer',
  name: 'Genderqueer'
}, {
  value: 'other',
  name: 'Not Listed'
}];