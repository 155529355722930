// Authentication Action Types
export const LOGIN_USER = 'LOGIN_USER';
export const CHECK_AUTH_STATUS = 'CHECK_AUTH_STATUS';
export const REGISTER_USER = 'REGISTER_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const UPDATE_EMAIL_ATTRIBUTE = 'UPDATE_EMAIL_ATTRIBUTE';
export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const LOGOUT_USER = 'LOGOUT_USER';
export const IS_ADMIN = 'IS_ADMIN';

export const FACEBOOK_LOGIN_USER = 'FACEBOOK_LOGIN_USER';
export const FACEBOOK_LOGOUT_USER = 'FACEBOOK_LOGOUT_USER';
export const GOOGLE_LOGIN_USER = 'GOOGLE_LOGIN_USER';
export const GOOGLE_LOGOUT_USER = 'GOOGLE_LOGOUT_USER';

// Display Action Types
export const LOGIN_CLICK = 'LOGIN_CLICK';
export const LOGIN_CLOSE = 'LOGIN_CLOSE';
export const FORGOT_PASSWORD_CLICK = 'FORGOT_PASSWORD_CLICK';
export const FORGOT_PASSWORD_CLOSE = 'FORGOT_PASSWORD_CLOSE';
export const SIGNUP_CLICK = 'SIGNUP_CLICK';
export const SIGNUP_CLOSE = 'SIGNUP_CLOSE';
export const UNVERIFIED_USER = 'UNVERIFIED_USER';
export const VERIFICATION_CLOSE = 'VERIFICATION_CLOSE';
export const CONFIRMATION_CLOSE = 'CONFIRMATION_CLOSE';

// Authentication Error Action Types
export const AUTH_ERROR = 'AUTH_ERROR';
export const HANDLE_ERROR = 'HANDLE_ERROR';