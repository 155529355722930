export default [
  'Addiction Medicine',
  'Adolescent Medicine',
  'Adult Psychiatry',
  'Aerospace Medicine',
  'Allergy / Immunology',
  'Allergy Medicine',
  'Anesthesiology',
  'Bariatric Surgery',
  'Behavioral Medicine',
  'Breast Surgery',
  'Cardiac Electrophysiology',
  'Cardiac Surgery',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Child Psychiatry',
  'Colorectal Surgery',
  'Cosmetic Surgery',
  'Critical Care Medicine',
  'Critical Care Pediatrics',
  'Dermatology',
  'Dermatopathology',
  'Diabetology',
  'Diagnostic Radiology',
  'Emergency Medicine',
  'Endocrinology',
  'ENT / Otolaryngology',
  'Epileptology',
  'Family Medicine',
  'Foot and Ankle Orthopedics',
  'Functional Medicine',
  'Gastroenterology',
  'General Practice',
  'General Surgery',
  'Geriatrics',
  'Gynecology Oncology',
  'Gynecology',
  'Hand Surgery',
  'Head and Neck Surgery',
  'Hematology',
  'Hematologic Oncology',
  'Hepatology',
  'Holistic Medicine',
  'Immunology',
  'Infectious Disease Medicine',
  'Internal Medicine',
  'Interventional Cardiology',
  'Interventional Pain Management',
  'Medical Genetics',
  'Neonatology',
  'Nephrology',
  'Neurology',
  'Neuroradiology',
  'Neurosurgery',
  'Nuclear Cardiology',
  'Nuclear Medicine',
  'Nursing',
  'Obstetrics and Gynecology',
  'Obstetrics',
  'Occupational Medicine',
  'Oncology',
  'Ophthalmology',
  'Orthopedic Spine Surgery',
  'Orthopedic Surgery',
  'Osteopathic Medicine',
  'Pain Management',
  'Palliative Care',
  'Pathology',
  'Pediatric Cardiology',
  'Pediatric Endocrinology',
  'Pediatric Gastroenterology',
  'Pediatric Hematology / Oncology',
  'Pediatric Internal Medicine',
  'Pediatric Neurology',
  'Pediatric Pulmonology',
  'Pediatric Radiology',
  'Pediatric Surgery',
  'Pediatrics',
  'Physiatrist',
  'Physical Medicine and Rehabilitation',
  'Plastic Surgery',
  'Preventive Medicine',
  'Psychiatry',
  'Pulmonary Critical Care',
  'Pulmonology',
  'Radiation Oncology',
  'Radiology',
  'Reconstructive Orthopedic Surgery',
  'Reproductive Endocrinology',
  'Rheumatology',
  'Sleep Medicine',
  'Sports Medicine',
  'Surgical Oncology',
  'Thoracic Surgery',
  'Urogynecology',
  'Urology',
  'Vascular and Interventional Radiology',
  'Vascular Surgery'
];