export const CREATE_STUDENT = 'CREATE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS';
export const GET_STUDENT_FAILURE = 'GET_STUDENT_FAILURE';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENTS_FAILURE = 'GET_STUDENTS_FAILURE';

export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
export const GET_DOCTOR_SUCCESS = 'GET_DOCTOR_SUCCESS';
export const GET_DOCTOR_FAILURE = 'GET_DOCTOR_FAILURE';
export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_DOCTORS_FAILURE = 'GET_DOCTORS_FAILURE';

export const CLEAR_USER = 'CLEAR_USER';

export const USER_ERROR = 'USER_ERROR';
export const HANDLE_ERROR = 'HANDLE_ERROR';